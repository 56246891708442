import React, { useState, useEffect } from "react";
import HighStockWrapper from '../../components/chart/HighStockWrapper';
// import { _exchange_symbol_lookup } from '../../universalSymbolMap';
// console.log(_exchange_symbol_lookup)

document.title = "Reynolds";

// consider sorting better than alphabetically 
const rootMap = {
    '6A': ['Austrarian Dollar', 'AD1 A:00_0_R Curncy'],
    '6B': ['British Pound', 'BP1 A:00_0_R Curncy'],
    '6C': ['Canada Dollar', 'CD1 A:00_0_R Curncy'],
    '6E': ['Euro', 'EC1 A:00_0_R Curncy'],
    '6J': ['Japan Yen', 'JY1 A:00_0_R Curncy'],
    '6L': ['Brazil Real', 'BR1 A:00_0_R Curncy'],
    '6M': ['Mexican Peso', 'PE1 A:00_0_R Curncy'],
    'BTC': ['Bitcoin', 'BTC1 A:00_0_R Curncy'],
    'CNH': ['China Yuan', 'CHY1 A:00_0_R Curncy'],
    'DX': ['Dollar Index', 'DX1 A:00_0_R Curncy'],
    'ETH': ['Etherium', 'DCR1 A:00_0_R Curncy'],
    'CL': ['Crude Oil', 'CL1 R:02_0_R Comdty'],
    'NG': ['Natural Gas', 'NG1 R:02_0_R Comdty'],
    'HO': ['Heating Oil', 'HO1 R:02_0_R Comdty'],
    'RB': ['Rbob', 'XB1 R:02_0_R Comdty'],
    'ZS': ['Soybean', 'S 1 A:00_0_R Comdty'],
    'ZL': ['Soy Oil', 'BO1 A:00_0_R Comdty'],
    'ZW': ['Chicago Wheat', 'W 1 A:00_0_R Comdty'],
    'KE': ['Kansas Wheat', 'KW1 A:00_0_R Comdty'],
    'MWE': ['Minnesota Wheat', 'MW1 A:00_0_R Comdty'],
    'ZM': ['SoyMeal', 'SM1 A:00_0_R Comdty'],
    'ZC': ['Corn', 'C 1 A:00_0_R Comdty'],
    'ZF': ['5yr Notes', 'FV1 A:00_0_R Comdty'],
    'ZB': ['T-Bonds', 'US1 A:00_0_R Comdty'],
    'ZN': ['10yr Notes', 'TY1 A:00_0_R Comdty'],
    'UB': ['Ultra Bonds', 'WN1 A:00_0_R Comdty'],
    'HE': ['Lean Hog', 'LH1 A:00_0_R Comdty'],
    'LE': ['Live Cattle', 'LC1 A:00_0_R Comdty'],
    'GF': ['Feeder Cattle', 'FC1 A:00_0_R Comdty'],
    'GC': ['Gold', 'GC1 A:00_0_R Comdty'],
    'HG': ['Copper', 'HG1 A:00_0_R Comdty'],
    'PL': ['Platinum', 'PL1 A:00_0_R Comdty'],
    'PA': ['Paladium', 'PA1 A:00_0_R Comdty'],
    'SI': ['Silver', 'SI1 A:00_0_R Comdty'],
    'ES': ['E mini', 'ES1 A:00_0_R Index'],
    'NQ': ['Nasdaq Index', 'NQ1 A:00_0_R Index']
};

const wsUrl = (document.location.protocol === 'https:')
    ? "wss://app.cbot.comatl.com:49534/"
    : "ws://app.cbot.comatl.com:49533/";

// establishes WebSocket hydrodynamics connection and charts Reynolds value for a specified root
const ReynoldsChart = (props) => {

    const root = props.root;
    const rootTitle = rootMap[root][0];
    // console.log(root)

    const [chartData, setChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        let ws;

        const fetchChartData = () => {
            ws = new WebSocket(wsUrl);

            ws.onopen = () => {
                console.log("sending WS message....")
                ws.send(`{"type": "start", "source": "hydrodynamics", "args": {"root":"${root}", "interval":0}}`);
            }

            ws.onmessage = (evt) => {
                try {
                    const msg = JSON.parse(evt.data);
                    if (msg.type === "started") {
                        console.log("WS starting...");
                    } else if (msg.type === "data") {
                        console.log(msg.data);
                        // setChartData(msg.data);
                        setChartData((msg.data).filter((ele) => ele[0] === 4));
                        setIsLoading(false);
                        ws.close();
                    } else {
                        console.log("unrecognized WS message")
                    }
                    // console.log(data);
                    // setChartData(data);
                } catch (err) {
                    console.error(err);
                    setError("Failed to parse WS msg")
                } finally {
                    // setIsLoading(false);
                    // ws.close();
                }
            };

            ws.onerror = (err) => {
                console.error(err);
                setError("WS Error");
                setIsLoading(false);
                if (ws) ws.close();
            }

            ws.onclose = () => {
                console.log("WS closed")
            }
        };

        fetchChartData();

        return () => {
            if (ws) {
                ws.close();
            }
        };



    }, []);

    if (isLoading || !chartData) return <p>{`${rootTitle} loading...`}</p>
    if (error) return <p>{error}</p>

    if (chartData) {
        // console.log(chartData);
    
        const date_idx = 1;
        const price_idx = 2;
        const val_idx = 4;
        const lower_idx = 5;
        const upper_idx = 6;
        const contract_idx = 7;

        let seriesData0 = [];
        let seriesData1 = [];
        let seriesData2 = [];
        let seriesData3 = [];

        for (let i = 0; i < chartData.length; i++) {
            const cur = chartData[i]
            seriesData0.push([Date.parse(cur[date_idx]), cur[price_idx], cur[contract_idx]]);
            seriesData1.push([Date.parse(cur[date_idx]), cur[val_idx]]);
            seriesData2.push([Date.parse(cur[date_idx]), cur[lower_idx]]);
            seriesData3.push([Date.parse(cur[date_idx]), cur[upper_idx]]);
        }

        const chartOptions = {
            chart: {
                animation: false,
            },
            title: {
                // text: `${root} | Price vs Reynold's Number = Inertial / Viscous = Velocity / Kinetic Viscocity (estimated)`
                text: rootTitle
            },
            legend: {
                enabled: true,
            },
            navigator: {
                enabled: true
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2
            },
            plotOptions: { 
                series: {
                    animation: false,
                    connectNulls: true
                },
            },
            xAxis: {
                type: "datetime",
                events: {
                    // setExtremes: (e) => { setAllExtremes(e); }
                }
            },
            yAxis: [
                { 
                    title: { text: "Price" },
                    opposite: false
                },
                { 
                    title: { text: "Reynold's Number"},
                }
            ],
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "price",
                    data: seriesData0,
                    yAxis: 0,
                    color: 'blue',
                    keys: ['x', 'y', 'contract'],
                    tooltip: {
                        pointFormatter: function() {
                            return '<div>' +
                            '<p> [' + this.contract + ']</p>' +
                            '<p> price: ' + this.y + '</p>' +
                            '</div>'
                        }
                    }
                },
                {
                    name: "Reynold's number",
                    data: seriesData1,
                    yAxis: 1,
                    color: 'green'
                },
                {
                    name: "lower",
                    data: seriesData2,
                    yAxis: 1,
                    color: 'orange'
                },
                {
                    name: "upper",
                    data: seriesData3,
                    yAxis: 1,
                    color: 'red'
                }
            ],
        };

        return (
            <>
               <HighStockWrapper config={chartOptions} />
            </>
        );
    } else {
        return (<p>?</p>);
    }
};

const ReynoldsList = () => {
    return (
        <>
            <h3 style={{textAlign: 'center'}}>
                Price vs Reynold's Number = Inertial / Viscous = Velocity / Kinetic Viscocity (estimated)
            </h3>
            <div>
                {Object.keys(rootMap).map((curRoot, index) => (
                    <ReynoldsChart key={index} root={curRoot} />
                ))}
            </div>
        </>
    );
};

export default ReynoldsList;